<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="项目列表" name="first">
        <projectList v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="项目类型" name="second">
        <projectType v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import projectList from "./components/projectList.vue";
import projectType from "./components/projectType.vue";
import { mapState } from 'vuex';

export default {
  name: "projectIntroduction",
  components: {
    projectList,
    projectType,
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>





