<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="display: flex">
        <el-input
            style="width: 300px!important;"
            class="w120 mb10 mr10"
            placeholder="请选择项目名称"
            v-model="filterName"
            clearable
        />
        <el-select
            clearable
            v-model="typeProId"
            style="width: 300px!important;"
            placeholder="请选择项目类型"
        >
          <el-option
              v-for="(item) in typeList"
              :key="item.typeId"
              :label="item.typeName"
              :value="item.typeId"
          />
        </el-select>
        <el-button type="primary" style="height: 34px!important;margin-left: 20px" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 新增项目
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="projectName" align="center" label="项目名称"/>
        <el-table-column align="center" label="项目封面">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.coverImg"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="projectDoc" align="center" label="项目链接"/>
        <el-table-column prop="typeName" align="center" label="项目类型"/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort" min="0"
                        @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')" placeholder="数值越大，排序越靠前"
                        @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.projectId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增项目"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24 project_wrap"
          label-position="left"
      >
        <el-form-item prop="title" label="项目名称">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入项目名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="分类">
          <el-select
              clearable
              v-model="ruleForm.type"
              style="width: 100%"
              placeholder="请选择分类"
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileListImg"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <!--              上传图片的最佳尺寸：4:9，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M-->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传文档" prop="coverFile">
          <div class="flex-div uploaditem">
            <el-tooltip class="item" effect="dark" :content="tag.name" placement="top-start"
                        v-for="(tag,index) in fileList" :key="index">
              <el-tag style="" :disable-transitions="false" @close="handleClose(index)" closable
                      @click="downloadFile(tag)"><i class="el-icon-paperclip"></i><span
                  class="tagtext">{{ tag.name }}</span></el-tag>
            </el-tooltip>
            <el-upload
                v-if="fileList.length==0"
                class="upload-demo"
                :action="$store.state.uploadingUrl"
                ref="upload"
                :limit="1"
                :on-remove="handleRemoveFile"
                :on-success="handleSuccessFile"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :show-file-list="false"
            >
              <el-button class="btn" style="height: 40px"><i class="el-icon-paperclip"></i>上传附件</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort" min="0"
                    @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="项目介绍" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm.details"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{ TiLength }}/10000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请输入内容'
import {queryProjectTypePage, queryProjectPage, addProject,updateProject,delProject} from "@/api/chinaMerchants";

export default {
  name: "projectList",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeProId:'',
      fileType: ["pdf", "doc", "docx", "xls", "xlsx"],
      TiLength: 0,
      editorOption: quillConfig,
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        details: "",
        coverImg: "",
        ifShow: false,
        type: '',
        sort: "",
        coverFile: "",
      },
      fileListImg: [],
      fileList: [],
      dialogVisible: false,
      rules: {
        title: [{required: true, message: "请输入标题", trigger: "blur"}],
        details: [{required: true, message: "请输入内容", trigger: "blur"}],
        coverImg: [{required: true, message: "请上传图片"}],
        coverFile: [{required: true, message: "请上传文件"}],
        type: [{required: true, message: "请选择类型"}],
        sort: [{required: true, message: "请选择排序"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{label: "全部", value: 1}],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate: 1,
      projectId: "",
      srcList: [],
      ossUrl: this.$store.state.ossUrl,
      typeList: []
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.getList()
    this.queryPage();
  },
  methods: {
    ready() {
      Quill.register({'formats/lineHeight': lineHeightStyle}, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    async getList() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await queryProjectTypePage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        this.typeList = list;
        this.total = total;
      } catch (error) {
        this.typeList = []
        this.loading = false;
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryProjectPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].coverImg) {
            a.push(list[i].coverImg)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      this.ruleForm.title = data.projectName;
      this.ruleForm.details = data.content;
      this.projectId = data.projectId
      this.ruleForm.sort = data.sort
      this.ruleForm.coverImg = data.coverImg
      this.ruleForm.coverFile = data.projectDoc
      this.ruleForm.type = data.typeId
      this.fileList = [{ name: data.projectDoc, uid: 1 }];
      this.fileListImg = [{ url: data.coverImg, uid: 1 }];
    },
    //删除弹窗
    async del(val) {
      try {
        await delProject({projectId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        projectName: this.filterName,
        typeId: this.typeProId,
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      let data = {
        projectName: this.filterName,
        typeId: this.typeProId,
      };
      this.currentPage = val;
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      let data = {
        projectName: this.filterName,
        typeId: this.typeProId,
      };
      this.pageSize = val;
      this.queryPage(data);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        details: "",
        coverImg: "",
        type: '',
        sort: "",
        coverFile: "",
      }),
          this.centerDialogVisible = false;
      this.fileList = []
      this.fileListImg = []
    },
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              projectName: this.ruleForm.title,
              coverImg: this.ruleForm.coverImg,
              projectDoc: this.ruleForm.coverFile,
              typeId: this.ruleForm.type,
              sort: this.ruleForm.sort,
              content: this.ruleForm.details,
              isDel: 0,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addProject(data);
            } else {
              data.projectId = this.projectId
              await updateProject(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleRemove(file, fileList) {
      this.fileListImg = []
      this.ruleForm.coverImg = "";
    },

    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileListImg = fileList;
    },

    handleRemoveFile(file, fileList) {
      this.fileList = []
      this.ruleForm.coverFile = "";
    },

    handleSuccessFile(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverFile = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$showMessage('上传文件大小不能超过 50MB!');
          return false;
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    handleClose(index) {
      this.fileList.splice(index, 1)
    },
    downloadFile() {

    },
    async saveSort(row) {
      if (row && row.sort != "") {
        let ajax = {
          sort: row.sort,
          projectId: row.projectId
        }
        const result = await updateProject(ajax);
        console.log("result:", result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .project_wrap .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .project_wrap .el-upload--text {
  width: 150px;
  height: 150px;
}

.el-icon-paperclip {
  margin-right: 10px;
}
</style>




