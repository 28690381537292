import httpUtil from "@/utils/httpUtil";

/**新增轮播 */
export const addAttractInvestmentImg =  params => httpUtil.post("/api/assPc/FunctionIPC/addAttractInvestmentImg", params);

/**查询轮播 */
export const queryAttractInvestmentImgPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryAttractInvestmentImgPage", params);

/**修改轮播 */
export const updateAttractInvestmentImg =  params => httpUtil.post("/api/assPc/FunctionIPC/updateAttractInvestmentImg", params);

/**删除轮播 */
export const delAttractInvestmentImg =  params => httpUtil.post("/api/assPc/FunctionIPC/delAttractInvestmentImg", params);

/**新增开发区 */
export const addAttractInvestmentKFQ =  params => httpUtil.post("/api/assPc/FunctionIPC/addAttractInvestmentKFQ", params);

/** 查询开发区  */
export const queryAttractInvestmentKFQPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryAttractInvestmentKFQPage", params);

/** 查询开发区  */
export const updateAttractInvestmentKFQ =  params => httpUtil.post("/api/assPc/FunctionIPC/updateAttractInvestmentKFQ", params);

/** 开发区详情  */
export const queryAttractInvestmentKFQById =  params => httpUtil.post("/api/assPc/FunctionIPC/queryAttractInvestmentKFQById", params);

/** 删除开发区  */
export const delAttractInvestmentKFQ =  params => httpUtil.post("/api/assPc/FunctionIPC/delAttractInvestmentKFQ", params);

/**  新增分类 */
export const addProjectType =  params => httpUtil.post("/api/assPc/FunctionIPC/addProjectType", params);

/**  分类列表 */
export const queryProjectTypePage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryProjectTypePage", params);

/**  删除分类 */
export const delProjectType =  params => httpUtil.post("/api/assPc/FunctionIPC/delProjectType", params);

/**  修改分类 */
export const updateProjectType =  params => httpUtil.post("/api/assPc/FunctionIPC/updateProjectType", params);

/**  新增项目推介 */
export const addProject =  params => httpUtil.post("/api/assPc/FunctionIPC/addProject", params);

/**  项目推介列表 */
export const queryProjectPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryProjectPage", params);

/**  修改项目推介 */
export const updateProject =  params => httpUtil.post("/api/assPc/FunctionIPC/updateProject", params);

/**  删除项目推介 */
export const delProject =  params => httpUtil.post("/api/assPc/FunctionIPC/delProject", params);

/**  新增产品介绍 */
export const addProduct =  params => httpUtil.post("/api/assPc/FunctionIPC/addProduct", params);

/**  产品列表 */
export const queryProductPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryProductPage", params);

/**  修改产品 */
export const updateProduct =  params => httpUtil.post("/api/assPc/FunctionIPC/updateProduct", params);

/**  删除产品 */
export const delProduct =  params => httpUtil.post("/api/assPc/FunctionIPC/delProduct", params);

/**  产品详情 */
export const queryProductById =  params => httpUtil.post("/api/assPc/FunctionIPC/queryProductById", params);


/**  新增企业介绍 */
export const addCompanyRecommend =  params => httpUtil.post("/api/assPc/FunctionIPC/addCompanyRecommend", params);

/**  新增企业介绍 */
export const queryCompanyRecommendPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryCompanyRecommendPage", params);

/**  企业介绍删除 */
export const delCompanyRecommend =  params => httpUtil.post("/api/assPc/FunctionIPC/delCompanyRecommend", params);

/**  企业介绍修改 */
export const updateCompanyRecommend =  params => httpUtil.post("/api/assPc/FunctionIPC/updateCompanyRecommend", params);

/**  企业介绍详情 */
export const queryCompanyRecommendById =  params => httpUtil.post("/api/assPc/FunctionIPC/queryCompanyRecommendById", params);

/**  企业类型新增 */
export const addTjCompanyTypeType =  params => httpUtil.post("/api/assPc/FunctionIPC/addTjCompanyTypeType", params);

/**  企业类型修改 */
export const upTjCompanyType =  params => httpUtil.post("/api/assPc/FunctionIPC/upTjCompanyType", params);

/**  企业类型删除 */
export const delTjCompanyType =  params => httpUtil.post("/api/assPc/FunctionIPC/delTjCompanyType", params);

/**  企业类型列表 */
export const selectTjCompanyTypePageList =  params => httpUtil.post("/api/assPc/FunctionIPC/selectTjCompanyTypePageList", params);










